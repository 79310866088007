import { ProductsValue } from './products.value';
import { BaseParams, BaseParamsInterface } from '@app/store/models';

interface ParamsType extends BaseParamsInterface {
    item_category_code?: string;
    item_new_in?: string;
    item_promo?: string;
    search?: string;
    [key: string]: string | undefined;
}

class ProductsValueDto extends BaseParams {
    private _params: ParamsType;

    constructor(dto: ProductsValue) {
        super(dto);

        this._params = { ...this.baseParams };

        Object.keys(dto).forEach((key) => {
            switch (key) {
                case 'category':
                    this._params['item_category_code'] = dto.category;
                    break;

                case 'customCategory':
                    this._params['item_category_code'] = dto.customCategory;
                    break;

                case 'novelties':
                    this._params['item_new_in'] = 'true';
                    break;

                case 'promotions':
                    this._params['item_promo'] = 'true';
                    break;

                case 'search':
                    this._params['search'] = dto.search;
                    break;

                default:
                    this._params[key] = dto[key]?.toString();
            }
        });
    }

    get params(): Record<string, string> {
        return Object.fromEntries(
            Object.entries(this._params).filter((entry): entry is [key: string, value: string] => entry[1] !== undefined && entry[1] !== null),
        );
    }
}

export { ProductsValueDto };
